:root {
  // COLORS
  --white: #fdfdfd;
  --text: #85859e;
  --background: #00001f;
  --background-dark: #010118;

  --violet-primary: #6800f4;
  --violet-secondary: #d82af1;
  --violet-tertiary: #1c1c2e;
  --violet-4: #56567e;
  --violet-5: #141424;

  --nav: rgba(28, 28, 46, 0.95);
  --violet-transparent: rgba(28, 28, 46, 0.7);
  --violet-transparent-2: rgba(28, 28, 46, 0.5);

  --green-primary: #378f71;
  --green-secondary: #3eba8f;

  --text-static: #85859e;

  // SHADOW
  --shadow: 0 1rem 2rem rgba(0, 0, 0, 0.075);
  --shadow-dark: 0 1rem 2rem rgba(0, 0, 0, 0.2);

  // GRID
  --grid-width: 136.6rem;
  --grid-gap: 3rem;

  // FONT
  --font-family: 'Maven Pro', sans-serif;
  --font-display: 'Montserrat', sans-serif;
  --default-font-size: 1.7rem;
}

:root.light {
  // COLORS
  --text: #00001f;
  --background: #e3e3eb;
  --white: #56567e;
  --violet-tertiary: #85859e;
  --violet-transparent: rgba(28, 28, 46, 0.1);
  --violet-transparent-2: rgba(28, 28, 46, 0.075);
  --violet-5: #b3b3c5;
}
