*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%; //1 rem = 10px; 10px/16px = 62.5%
  scroll-behavior: smooth;
}

body {
  box-sizing: border-box;
  background-color: var(--background);
  position: relative;
}

//Scrollbar
body::-webkit-scrollbar {
  width: 1rem;
}

body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: var(--white);
}

body::-webkit-scrollbar-thumb {
  background-color: var(--violet-tertiary);
  outline: none;
}

::selection {
  background-color: var(--violet-tertiary);
  color: var(--text);
}
