.footer {
  padding: 12rem 0 3rem 0;
  background-color: var(--violet-5);

  @include respond(tab-land) {
    padding: 8rem 1rem 2rem 1rem;
  }

  .row {
    // display: grid;
    // grid-template-columns: repeat(2, 1fr);
    // grid-gap: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 80rem;

    @include respond(tab-port) {
      width: 100%;
      padding: 0 1rem;
    }
  }

  &__box {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    span {
      color: var(--beige);

      &:not(:last-child) {
        margin-bottom: 1rem;
      }
    }

    &:not(:last-child) {
      margin-bottom: 3rem;
    }
  }

  &__title {
    color: var(--white);
    font-size: 3rem;
    font-weight: 300;
    margin-bottom: 1rem;
  }

  &__icon {
    color: var(--white);
    margin-right: 1rem;
  }

  &__small {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.4rem;
    font-style: italic;
  }

  &__img {
    width: 2rem;
    margin-left: 1rem;
  }

  .paragraph {
    color: var(--text);
  }

  .default-link {
    color: var(--white);
  }

  .logo-inline-dark,
  .logo-inline-light {
    height: 6rem;
    margin-bottom: 2rem;
  }
}
