.jumbotron {
  font-size: 4rem;
  font-weight: 300;
  color: var(--violet-secondary);
  width: 60%;
  line-height: 1.4;
  display: flex;
  margin: 0 auto;
  text-align: center;
  padding: 12rem 0 6rem 0;
  display: flex;
  align-items: center;
  height: 30rem;

  @include respond(tab-port-big) {
    width: 90%;
  }

  @include respond(phone-big) {
    font-size: 2.4rem;
    height: 15rem;
    padding: 6rem 0 0;
  }
}
