:root {
  --white: #fdfdfd;
  --text: #85859e;
  --background: #00001f;
  --background-dark: #010118;
  --violet-primary: #6800f4;
  --violet-secondary: #d82af1;
  --violet-tertiary: #1c1c2e;
  --violet-4: #56567e;
  --violet-5: #141424;
  --nav: #1c1c2ef2;
  --violet-transparent: #1c1c2eb3;
  --violet-transparent-2: #1c1c2e80;
  --green-primary: #378f71;
  --green-secondary: #3eba8f;
  --text-static: #85859e;
  --shadow: 0 1rem 2rem #00000013;
  --shadow-dark: 0 1rem 2rem #0003;
  --grid-width: 136.6rem;
  --grid-gap: 3rem;
  --font-family: "Maven Pro", sans-serif;
  --font-display: "Montserrat", sans-serif;
  --default-font-size: 1.7rem;
}

:root.light {
  --text: #00001f;
  --background: #e3e3eb;
  --white: #56567e;
  --violet-tertiary: #85859e;
  --violet-transparent: #1c1c2e1a;
  --violet-transparent-2: #1c1c2e13;
  --violet-5: #b3b3c5;
}

@keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translateX(-10rem);
  }

  80% {
    transform: translateX(1rem);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translateX(10rem);
  }

  80% {
    transform: translateX(-1rem);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(3rem);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes moveInTop {
  0% {
    opacity: 0;
    transform: translateY(-2rem);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes opacity {
  0% {
    opacity: 0;
  }

  15% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes cursor {
  0%, 100% {
    border-color: var(--text);
  }
}

@keyframes text {
  0% {
    width: 0;
  }

  100% {
    width: 47.5ch;
  }
}

*, :after, :before {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
  font-size: 62.5%;
}

body {
  box-sizing: border-box;
  background-color: var(--background);
  position: relative;
}

body::-webkit-scrollbar {
  width: 1rem;
}

body::-webkit-scrollbar-track {
  background-color: var(--white);
  -webkit-box-shadow: inset 0 0 6px #0000004d;
}

body::-webkit-scrollbar-thumb {
  background-color: var(--violet-tertiary);
  outline: none;
}

::selection {
  background-color: var(--violet-tertiary);
  color: var(--text);
}

body {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: var(--default-font-size);
  color: var(--text);
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  line-height: 1.7;
}

.heading-primary {
  font-family: var(--font-display);
  background-image: linear-gradient(to right, var(--violet-primary), var(--violet-secondary));
  -webkit-background-clip: text;
  color: #0000;
  letter-spacing: .2rem;
  text-transform: uppercase;
  margin-bottom: 1rem;
  font-size: 5rem;
  line-height: 1.2;
  animation: 3s ease-out opacity;
}

@media only screen and (max-width: 56.25em) {
  .heading-primary {
    font-size: 3rem;
  }
}

.heading-primary--light {
  font-weight: 300;
  display: block;
}

.heading-secondary {
  font-family: var(--font-display);
  color: var(--text);
  margin-bottom: 3rem;
  font-size: 2.8rem;
  font-weight: 300;
  line-height: 1.2;
  animation: 1s ease-out moveInBottom;
}

@media only screen and (max-width: 56.25em) {
  .heading-secondary {
    font-size: 2.2rem;
  }
}

.heading-tertiary {
  font-family: var(--font-display);
  text-transform: uppercase;
  color: var(--violet-secondary);
  margin-bottom: .5rem;
  font-size: 2.8rem;
  font-weight: 400;
  line-height: 1.4;
}

.heading-4 {
  font-family: var(--font-display);
  letter-spacing: .5rem;
  font-size: 2rem;
  font-weight: 300;
  animation-name: moveInTop;
  animation-duration: 1s;
  animation-timing-function: ease-out;
}

@media only screen and (max-width: 48em) {
  .heading-4 {
    font-size: var(--default-font-size);
    letter-spacing: .3rem;
  }
}

.heading-5 {
  font-family: var(--font-display);
  color: var(--white);
  text-transform: uppercase;
  margin-bottom: 1.5rem;
  font-size: 2rem;
  font-weight: 300;
  line-height: 1.4;
}

.paragraph {
  font-size: var(--default-font-size);
  color: var(--text);
  font-family: var(--font-family);
  font-weight: 300;
}

.paragraph:not(:last-child) {
  margin-bottom: 3rem;
}

.cursor {
  color: var(--text);
  margin-left: .25em;
}

.blinkAnim {
  animation: 1s linear infinite blinkMe;
}

.animated-text {
  color: var(--text);
  margin-left: 1rem;
  font-style: italic;
}

@media only screen and (max-width: 48em) {
  .animated-text {
    font-size: 2.4rem;
  }
}

@keyframes blinkMe {
  0% {
    opacity: 0;
  }

  49% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

.btn, .btn:link, .btn:visited {
  width: max-content;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  border: none;
  border-radius: 3rem;
  align-items: center;
  padding: 1.5rem 4rem;
  font-size: 1.5rem;
  font-weight: 400;
  text-decoration: none;
  transition: all .2s;
  position: relative;
}

.btn:active, .btn:focus {
  outline: none;
}

.btn--primary {
  background-image: linear-gradient(to right top, var(--violet-primary), var(--violet-secondary));
  color: #fdfdfd;
  box-shadow: var(--shadow);
  transition: all .2s;
}

.btn--primary:hover {
  background-image: linear-gradient(to right top, var(--violet-secondary), var(--violet-primary));
  box-shadow: var(--shadow-dark);
}

.btn--secondary {
  background-image: linear-gradient(to right top, var(--green-primary), var(--green-secondary));
  color: #fdfdfd;
  box-shadow: var(--shadow);
  transition: all .2s;
}

.btn--secondary:hover {
  background-image: linear-gradient(to right top, var(--green-secondary), var(--green-primary));
  box-shadow: var(--shadow-dark);
}

.btn--tertiary {
  color: var(--text);
  padding: 0 !important;
  font-size: 1.8rem !important;
}

@media only screen and (max-width: 48em) {
  .btn--tertiary {
    font-size: var(--default-font-size) !important;
  }
}

.btn__icon {
  margin-left: .75rem;
  font-size: 1.5rem;
}

.default-link {
  color: var(--white);
}

.default-link:hover {
  text-decoration: none;
}

.switch {
  cursor: pointer;
  background: none;
  border: none;
  margin-right: 1rem;
  font-size: 1.5rem;
}

@media only screen and (max-width: 48em) {
  .switch {
    font-size: var(--default-font-size);
  }
}

.switch__icon {
  color: var(--text);
}

.logo-container {
  align-items: center;
  display: flex;
}

.logo-container .logo-symbol {
  margin-right: 1rem;
  width: 2rem !important;
}

.logo-container .logo-inline-light, .logo-container .logo-inline-dark {
  height: 1.8rem;
}

.logo-container .logo-inline-dark, .logo-container__mobile {
  display: none;
}

@media only screen and (max-width: 75em) {
  .logo-container__mobile {
    margin-bottom: 3rem;
    display: block;
    position: absolute;
    top: 8rem;
  }
}

.logo-symbol {
  height: 8rem;
  margin-bottom: 3rem;
  animation: 1s moveInTop;
}

.logo-inline-light.logo-hidden {
  display: none;
}

.logo-inline-dark.logo-visible {
  display: block;
}

.section-img {
  width: 100%;
  box-shadow: var(--shadow);
  border-radius: 1rem;
  transition: all .2s;
}

.section-img:hover {
  box-shadow: var(--shadow-dark);
  transform: translateY(-3px);
}

@media only screen and (max-width: 75em) {
  .section-img {
    width: 70%;
    margin: 0 auto;
    display: block;
  }
}

@media only screen and (max-width: 48em) {
  .section-img {
    width: 100%;
  }
}

.faq-main-title {
  font-family: var(--font-display);
  color: var(--white);
  margin-bottom: 5rem;
  font-size: 2.8rem;
  font-weight: 300;
  line-height: 1.4;
}

@media only screen and (max-width: 56.25em) {
  .faq-main-title {
    font-size: 2.4rem;
  }
}

.faq-main-title--violet {
  color: var(--violet-secondary);
}

.faq {
  grid-gap: 6rem;
  grid-template-columns: repeat(2, 1fr);
  display: grid;
}

@media only screen and (max-width: 56.25em) {
  .faq {
    grid-template-columns: 1fr;
  }
}

.faq__box {
  grid-gap: 2rem;
  grid-template-columns: max-content 1fr;
  display: grid;
}

.faq__icon {
  font-size: 5rem;
  font-weight: 600;
  line-height: 1;
  font-family: var(--font-display);
  color: var(--violet-transparent);
  position: relative;
}

.faq__icon:before {
  content: "";
  width: 1.2rem;
  height: 1.2rem;
  background-color: var(--violet-secondary);
  border-radius: 1rem;
  position: absolute;
  top: 2.5rem;
  left: 50%;
  transform: translate(-50%, -50%);
}

.faq__title {
  color: var(--white);
  margin-bottom: 1rem;
  font-size: 2rem;
  font-weight: 400;
}

.hot-topic {
  width: 100%;
  text-align: center;
  flex-direction: column;
  align-items: center;
  margin: 5rem auto 0;
  padding-bottom: .5rem;
  display: flex;
}

@media only screen and (max-width: 48em) {
  .hot-topic {
    margin: 3rem auto 0;
  }
}

.hot-topic__title {
  color: var(--violet-4);
  margin-bottom: 1rem;
  padding: 0 1rem;
  font-size: 1.4rem;
  display: block;
}

.hot-topic__container {
  grid-gap: 1rem;
  grid-template-columns: repeat(4, max-content);
  justify-items: center;
  display: grid;
}

.hot-topic__container::-webkit-scrollbar {
  width: 1rem;
  height: .5rem;
}

.hot-topic__container::-webkit-scrollbar-track {
  background-color: var(--background);
}

.hot-topic__container::-webkit-scrollbar-thumb {
  background-color: var(--violet-tertiary);
  border-radius: 3rem;
  outline: none;
}

@media only screen and (max-width: 48em) {
  .hot-topic__container {
    padding: 0 1rem .5rem;
    overflow-x: scroll;
    width: 100% !important;
  }
}

.hot-topic__link {
  color: var(--text);
  background-color: var(--violet-transparent);
  border-radius: .5rem;
  padding: 1rem 1.5rem;
  font-size: 1.4rem;
  text-decoration: none;
  transition: all .2s;
}

.hot-topic__link:hover {
  box-shadow: var(--shadow);
  background-color: var(--violet-transparent-2);
}

.hot-topic__icon {
  color: var(--violet-secondary);
  margin-right: 1rem;
  font-size: 1.4rem;
}

.jumbotron {
  color: var(--violet-secondary);
  width: 60%;
  text-align: center;
  height: 30rem;
  align-items: center;
  margin: 0 auto;
  padding: 12rem 0 6rem;
  font-size: 4rem;
  font-weight: 300;
  line-height: 1.4;
  display: flex;
}

@media only screen and (max-width: 75em) {
  .jumbotron {
    width: 90%;
  }
}

@media only screen and (max-width: 48em) {
  .jumbotron {
    height: 15rem;
    padding: 6rem 0 0;
    font-size: 2.4rem;
  }
}

.nav {
  height: 5rem;
  align-items: center;
  list-style: none;
  display: flex;
}

@media only screen and (max-width: 75em) {
  .nav {
    display: none;
  }
}

.nav__item {
  height: 5rem;
  align-items: center;
  display: flex;
  position: relative;
  overflow: hidden;
}

.nav__item:after {
  content: "";
  width: 0;
  height: 2px;
  background-color: var(--violet-secondary);
  border-radius: 3rem;
  transition: width .2s linear;
  position: absolute;
  bottom: 0;
  left: 0;
}

.nav__item:hover:after {
  width: 20%;
}

.nav__link {
  font-size: var(--default-font-size);
  color: var(--text);
  font-weight: 400;
  line-height: 1;
  text-decoration: none;
  transition: all .2s;
  position: relative;
}

.nav__link:not(:last-child) {
  margin-right: 2rem;
}

.nav__link:hover {
  color: var(--violet-secondary);
}

.nav .logo-inline-light {
  display: block;
}

@media only screen and (max-width: 75em) {
  .nav-active.nav {
    z-index: 9999;
    width: 100%;
    height: 100vh;
    background-color: var(--nav);
    color: var(--text-static);
    box-shadow: var(--shadow-dark);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
  }

  .nav-active.nav .nav__item:after {
    display: none;
  }

  .nav-active.nav .nav__link {
    color: var(--text-static);
    margin-right: 0;
  }

  .nav-active.nav .nav__link:hover {
    color: var(--violet-secondary);
  }

  .nav-active.nav .nav-close {
    cursor: pointer;
    font-size: 2rem;
    display: block;
    position: absolute;
    top: 3rem;
    right: 3rem;
  }
}

.hamburger {
  cursor: pointer;
  margin-left: 1rem;
  font-size: 1.8rem;
  display: none;
}

@media only screen and (max-width: 48em) {
  .hamburger {
    font-size: var(--default-font-size);
  }
}

@media only screen and (max-width: 75em) {
  .hamburger {
    display: flex;
  }
}

.nav-close {
  display: none;
}

#vanta-canvas {
  width: 100%;
  height: 100vh;
  opacity: .3;
  position: absolute;
}

.footer {
  background-color: var(--violet-5);
  padding: 12rem 0 3rem;
}

@media only screen and (max-width: 86.4em) {
  .footer {
    padding: 8rem 1rem 2rem;
  }
}

.footer .row {
  max-width: 80rem;
  flex-direction: column;
  align-items: center;
  display: flex;
}

@media only screen and (max-width: 56.25em) {
  .footer .row {
    width: 100%;
    padding: 0 1rem;
  }
}

.footer__box {
  text-align: center;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.footer__box span {
  color: var(--beige);
}

.footer__box span:not(:last-child) {
  margin-bottom: 1rem;
}

.footer__box:not(:last-child) {
  margin-bottom: 3rem;
}

.footer__title {
  color: var(--white);
  margin-bottom: 1rem;
  font-size: 3rem;
  font-weight: 300;
}

.footer__icon {
  color: var(--white);
  margin-right: 1rem;
}

.footer__small {
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  font-style: italic;
  display: flex;
}

.footer__img {
  width: 2rem;
  margin-left: 1rem;
}

.footer .paragraph {
  color: var(--text);
}

.footer .default-link {
  color: var(--white);
}

.footer .logo-inline-dark, .footer .logo-inline-light {
  height: 6rem;
  margin-bottom: 2rem;
}

.header {
  height: 100vh;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.header .row {
  padding: 0 1rem;
}

@media only screen and (max-width: 75em) {
  .header .row {
    flex-direction: column-reverse;
    display: flex;
  }
}

.header__content {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.row {
  max-width: var(--grid-width);
  margin: 0 auto;
}

.navbar {
  width: 100%;
  z-index: 999;
  height: 5rem;
  position: absolute;
}

@media only screen and (max-width: 86.4em) {
  .navbar {
    padding: 0 1rem;
  }
}

.navbar .row {
  height: 5rem;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.navbar__sticky {
  background-color: var(--violet-5);
  box-shadow: var(--shadow);
  position: fixed;
}

.navbar-features {
  width: 15.9rem;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.navbar-features .toggle-icon {
  width: 2rem;
  height: 2rem;
  display: flex;
}

.section {
  padding: 12rem 0;
  transition: transform 1s, opacity 1s;
}

@media only screen and (max-width: 86.4em) {
  .section {
    padding: 8rem 1rem;
  }
}

.section__1 .row, .section__2 .row, .section__3 .row, .section__4 .row, .section__5 .row {
  grid-gap: 3rem;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  display: grid;
}

@media only screen and (max-width: 75em) {
  .section__1 .row, .section__3 .row, .section__5 .row {
    flex-direction: column-reverse;
    display: flex;
  }

  .section__2 .row, .section__4 .row {
    flex-direction: column;
    display: flex;
  }
}

.section-hidden {
  opacity: 0;
  transform: translateY(8rem);
}

/*# sourceMappingURL=index.48a4a90d.css.map */
