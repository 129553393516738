.navbar {
  width: 100%;
  position: absolute;
  z-index: 999;
  height: 5rem;

  @include respond(tab-land) {
    padding: 0 1rem;
  }

  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 5rem;
  }

  &__sticky {
    position: fixed;
    background-color: var(--violet-5);
    box-shadow: var(--shadow);
  }
}

.navbar-features {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  width: 15.9rem;

  .toggle-icon {
    width: 2rem;
    height: 2rem;
    display: flex;
  }
}
