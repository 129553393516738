.logo-container {
  display: flex;
  align-items: center;

  .logo-symbol {
    width: 2rem !important;
    margin-right: 1rem;
  }

  .logo-inline-light,
  .logo-inline-dark {
    height: 1.8rem;
  }

  .logo-inline-dark {
    display: none;
  }

  &__mobile {
    display: none;

    @include respond(tab-port-big) {
      display: block;
      position: absolute;
      top: 8rem;
      margin-bottom: 3rem;
    }
  }
}

.logo-symbol {
  height: 8rem;
  margin-bottom: 3rem;
  animation: moveInTop 1s;
}

.logo-inline-light.logo-hidden {
  display: none;
}

.logo-inline-dark.logo-visible {
  display: block;
}

.section-img {
  width: 100%;
  border-radius: 1rem;
  box-shadow: var(--shadow);
  transition: 0.2s ease;

  &:hover {
    transform: translateY(-3px);
    box-shadow: var(--shadow-dark);
  }

  @include respond(tab-port-big) {
    width: 70%;
    display: block;
    margin: 0 auto;
  }

  @include respond(phone-big) {
    width: 100%;
  }
}
