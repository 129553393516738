body {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: var(--default-font-size);
  line-height: 1.7;
  color: var(--text);
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}

.heading-primary {
  font-family: var(--font-display);
  font-size: 5rem;
  line-height: 1.2;
  background-image: linear-gradient(
    to right,
    var(--violet-primary),
    var(--violet-secondary)
  );
  -webkit-background-clip: text;
  color: transparent;
  letter-spacing: 0.2rem;
  text-transform: uppercase;
  margin-bottom: 1rem;
  animation: opacity 3s ease-out;

  @include respond(tab-port) {
    font-size: 3rem;
  }

  &--light {
    display: block;
    font-weight: 300;
  }
}

.heading-secondary {
  font-family: var(--font-display);
  font-size: 2.8rem;
  font-weight: 300;
  line-height: 1.2;
  margin-bottom: 1rem;
  color: var(--text);
  margin-bottom: 3rem;
  animation: moveInBottom 1s ease-out;

  @include respond(tab-port) {
    font-size: 2.2rem;
  }
}

.heading-tertiary {
  font-family: var(--font-display);
  font-size: 2.8rem;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  font-weight: 400;
  line-height: 1.4;
  color: var(--violet-secondary);
}

.heading-4 {
  font-family: var(--font-display);
  font-size: 2rem;
  font-weight: 300;
  letter-spacing: 0.5rem;
  animation-name: moveInTop;
  animation-duration: 1s;
  animation-timing-function: ease-out;

  @include respond(phone-big) {
    font-size: var(--default-font-size);
    letter-spacing: 0.3rem;
  }
}

.heading-5 {
  font-family: var(--font-display);
  color: var(--white);
  font-size: 2rem;
  line-height: 1.4;
  text-transform: uppercase;
  font-weight: 300;
  margin-bottom: 1.5rem;
}

.paragraph {
  font-size: var(--default-font-size);
  color: var(--text);
  font-family: var(--font-family);
  font-weight: 300;

  &:not(:last-child) {
    margin-bottom: 3rem;
  }
}

// Text Animation
.cursor {
  margin-left: 0.25em;
  color: var(--text);
}

.blinkAnim {
  animation: blinkMe 1s linear infinite;
}

.animated-text {
  color: var(--text);
  margin-left: 1rem;
  font-style: italic;

  @include respond(phone-big) {
    font-size: 2.4rem;
  }
}

@keyframes blinkMe {
  0% {
    opacity: 0;
  }
  49% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
