.section {
  padding: 12rem 0;
  transition: transform 1s, opacity 1s;

  @include respond(tab-land) {
    padding: 8rem 1rem;
  }

  &__1,
  &__2,
  &__3,
  &__4,
  &__5 {
    .row {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      align-items: center;
      grid-gap: 3rem;
    }
  }

  &__1,
  &__3,
  &__5 {
    .row {
      @include respond(tab-port-big) {
        display: flex;
        flex-direction: column-reverse;
      }
    }
  }

  &__2,
  &__4 {
    .row {
      @include respond(tab-port-big) {
        display: flex;
        flex-direction: column;
      }
    }
  }
}

.section-hidden {
  opacity: 0;
  transform: translateY(8rem);
}
