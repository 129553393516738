.faq-main-title {
  font-family: var(--font-display);
  font-size: 2.8rem;
  font-weight: 300;
  line-height: 1.4;
  margin-bottom: 3rem;
  color: var(--white);
  margin-bottom: 5rem;

  @include respond(tab-port) {
    font-size: 2.4rem;
  }

  &--violet {
    color: var(--violet-secondary);
  }
}

.faq {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 6rem;

  @include respond(tab-port) {
    grid-template-columns: 1fr;
  }

  &__box {
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-gap: 2rem;
  }

  &__icon {
    font-size: 5rem;
    line-height: 1;
    font-weight: 600;
    font-family: var(--font-display);
    position: relative;
    color: var(--violet-transparent);

    &::before {
      content: '';
      position: absolute;
      left: 50%;
      top: 2.5rem;
      transform: translate(-50%, -50%);
      width: 1.2rem;
      height: 1.2rem;
      background-color: var(--violet-secondary);
      border-radius: 1rem;
    }
  }

  &__title {
    font-size: 2rem;
    margin-bottom: 1rem;
    color: var(--white);
    font-weight: 400;
  }
}
