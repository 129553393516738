@keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translateX(-10rem);
  }

  80% {
    transform: translateX(1rem);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translateX(10rem);
  }

  80% {
    transform: translateX(-1rem);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(3rem);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes moveInTop {
  0% {
    opacity: 0;
    transform: translateY(-2rem);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes opacity {
  0% {
    opacity: 0;
  }

  15% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes cursor {
  0%,
  100% {
    border-color: var(--text);
  }
}

@keyframes text {
  0% {
    width: 0;
  }
  100% {
    width: 47.5ch;
  }
}
