.header {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  text-align: center;

  .row {
    padding: 0 1rem;

    @include respond(tab-port-big) {
      display: flex;
      flex-direction: column-reverse;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
