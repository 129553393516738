.hot-topic {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 0.5rem;
  margin: 5rem auto 0;
  text-align: center;
  align-items: center;

  @include respond(phone-big) {
    margin: 3rem auto 0;
  }

  &__title {
    color: var(--violet-4);
    font-size: 1.4rem;
    display: block;
    margin-bottom: 1rem;
    padding: 0 1rem;
  }

  &__container {
    display: grid;
    grid-template-columns: repeat(4, max-content);
    justify-items: center;
    grid-gap: 1rem;

    &::-webkit-scrollbar {
      width: 1rem;
      height: 0.5rem;
    }

    &::-webkit-scrollbar-track {
      background-color: var(--background);
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--violet-tertiary);
      border-radius: 3rem;
      outline: none;
    }

    @include respond(phone-big) {
      width: 100% !important;
      overflow-x: scroll;
      padding: 0 1rem 0.5rem;
    }
  }

  &__link {
    text-decoration: none;
    color: var(--text);
    font-size: 1.4rem;
    background-color: var(--violet-transparent);
    padding: 1rem 1.5rem;
    border-radius: 0.5rem;
    transition: 0.2s ease;

    &:hover {
      box-shadow: var(--shadow);
      background-color: var(--violet-transparent-2);
    }
  }

  &__icon {
    font-size: 1.4rem;
    margin-right: 1rem;
    color: var(--violet-secondary);
  }
}
