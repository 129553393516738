.nav {
  display: flex;
  align-items: center;
  height: 5rem;
  list-style: none;

  @include respond(tab-port-big) {
    display: none;
  }

  &__item {
    height: 5rem;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 0;
      height: 2px;
      border-radius: 3rem;
      background-color: var(--violet-secondary);
      transition: 0.2s width linear;
    }

    &:hover {
      &:after {
        width: 20%;
      }
    }
  }

  &__link {
    position: relative;
    font-size: var(--default-font-size);
    line-height: 1;
    font-weight: 400;
    color: var(--text);
    text-decoration: none;
    transition: 0.2s ease;

    &:not(:last-child) {
      margin-right: 2rem;
    }

    &:hover {
      color: var(--violet-secondary);
    }
  }

  .logo-inline-light {
    display: block;
  }
}

@include respond(tab-port-big) {
  .nav-active.nav {
    position: fixed;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    top: 0;
    left: 0;
    padding: 0;
    display: flex;
    width: 100%;
    height: 100vh;
    background-color: var(--nav);
    color: var(--text-static);
    box-shadow: var(--shadow-dark);

    .nav__item {
      &::after {
        display: none;
      }
    }

    .nav__link {
      color: var(--text-static);
      margin-right: 0;

      &:hover {
        color: var(--violet-secondary);
      }
    }

    .nav-close {
      display: block;
      position: absolute;
      top: 3rem;
      right: 3rem;
      font-size: 2rem;
      cursor: pointer;
    }
  }
}

.hamburger {
  display: none;
  margin-left: 1rem;
  font-size: 1.8rem;
  cursor: pointer;

  @include respond(phone-big) {
    font-size: var(--default-font-size);
  }

  @include respond(tab-port-big) {
    display: flex;
  }
}

.nav-close {
  display: none;
}
