.btn {
  &,
  &:link,
  &:visited {
    text-decoration: none;
    padding: 1.5rem 4rem;
    align-items: center;
    width: max-content;
    border-radius: 3rem;
    transition: all 0.2s;
    position: relative;
    font-size: 1.5rem;
    font-weight: 400;
    text-align: center;
    text-transform: uppercase;

    border: none;
    cursor: pointer;
  }

  &:active,
  &:focus {
    outline: none;
  }

  &--primary {
    background-image: linear-gradient(
      to right top,
      var(--violet-primary),
      var(--violet-secondary)
    );
    color: #fdfdfd;
    box-shadow: var(--shadow);
    transition: 0.2s ease;

    &:hover {
      background-image: linear-gradient(
        to right top,
        var(--violet-secondary),
        var(--violet-primary)
      );
      box-shadow: var(--shadow-dark);
    }
  }

  &--secondary {
    background-image: linear-gradient(
      to right top,
      var(--green-primary),
      var(--green-secondary)
    );
    color: #fdfdfd;
    box-shadow: var(--shadow);
    transition: 0.2s ease;

    &:hover {
      background-image: linear-gradient(
        to right top,
        var(--green-secondary),
        var(--green-primary)
      );
      box-shadow: var(--shadow-dark);
    }
  }

  &--tertiary {
    color: var(--text);
    padding: 0 !important;
    font-size: 1.8rem !important;

    @include respond(phone-big) {
      font-size: var(--default-font-size) !important;
    }
  }

  &__icon {
    font-size: 1.5rem;
    margin-left: 0.75rem;
  }
}

.default-link {
  color: var(--white);

  &:hover {
    text-decoration: none;
  }
}

.switch {
  margin-right: 1rem;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;

  @include respond(phone-big) {
    font-size: var(--default-font-size);
  }

  &__icon {
    color: var(--text);
  }
}
