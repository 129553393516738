@mixin respond($breakpoint) {
  @if $breakpoint == phone-small {
    @media only screen and (max-width: 25.714em) {
      @content;
    } //360px
  }
  @if $breakpoint == phone {
    @media only screen and (max-width: 37.5em) {
      @content;
    } //600px
  }
  @if $breakpoint == phone-big {
    @media only screen and (max-width: 48em) {
      @content;
    } //768px
  }
  @if $breakpoint == tab-port {
    @media only screen and (max-width: 56.25em) {
      @content;
    } //900px
  }
  @if $breakpoint == tab-port-min {
    @media only screen and (min-width: 75em) {
      @content;
    } //900px
  }
  @if $breakpoint == tab-port-big {
    @media only screen and (max-width: 75em) {
      @content;
    } //1200px
  }
  @if $breakpoint == tab-land {
    @media only screen and (max-width: 86.4em) {
      @content;
    } //1366px
  }
  @if $breakpoint == tab-land-min {
    @media only screen and (min-width: 86.4em) {
      @content;
    } //1366px
  }
  @if $breakpoint == tab-land-big {
    @media only screen and (max-width: 87.5em) {
      @content;
    } //1400px
  }

  @if $breakpoint == desktop {
    @media only screen and (max-width: 100em) {
      @content;
    } //1600px
  }

  @if $breakpoint == desktop-big {
    @media only screen and (max-width: 112.5em) {
      @content;
    } //1800px
  }
}
